<template>
  <div @contextmenu="setContextIdWidget">
    <input
      class="hidden"
      type="button"
      @click="printDiv('printableArea')"
      value="print a div!"
    />
    <section>
      <Correlation_leftBar
        :displayId="displayId"
        :widgetId="widgetId"
        :widgetIndex="widgetIndex"
        :correlationIdx="idx"
        :displayIndex="displayIndex"
        :key="randomId"
        
      />
      <input
        type="checkbox"
        id="sidebar-btn"
        class="sidebar-btn"
        :checked="
          this.$store.state.disp.rects[this.displayIndex]?.isCorrelationLeftBar
        "
      />
      <label class="hidden" for="sidebar-btn">{{
        parseInt(width) /
        this.$store.state.rect.rects[idx]?.wellsToRelate?.length
      }}</label>
      <section
        class="content"
        v-bind:class="{
          content: true,
          // hidden:
          //   !this.$store.state.disp.rects[this.displayIndex]?.isCorrelationMap,
          hidden: false,
          // hidden: true
        }"
      >
        <div class="container2" 
          :style="!this.$store.state.disp.rects[this.displayIndex]?.isCorrelationMap ? {position: 'fixed', top: '-100%', height: '100vh'} : ''">
          <CoreMap
            :displayId="displayId"
            :widgetId="widgetId"
            :widgetIndex="widgetIndex"
            :displayIndex="displayIndex"
            :correlationIdx="idx"
            :height="parentH"
            :width="parentW - 5"
            class="plot"
            :isReplay="isReplay"
            
          />
          <!-- <div v-if="!this.$store.state.disp.rects[this.displayIndex]?.isCorrelationMap" class="absolute" :style="{
            height: parentH + 'px',
            width: parentW - 5 + 'px',
            background: 'var(--widgetBg) !important',
          }"></div> -->
        </div>
      </section>
      <div
        id="printableArea"
        v-bind:class="{
          content: true,
          hidden:
            this.$store.state.disp.rects[this.displayIndex]?.isCorrelationMap,
        }"
      >
        <div class="container2" :style="{maxHeight: `${height}px`}" >
          <div
            class="plot"
            v-for="(well, index) in this.$store.state.rect.rects[
              this.idx
            ]?.wellsToRelate"
            :id="widgetId + index + well.wellId"
            :key="index"
          >
            <!-- <CorrelationBar
              :selectdWell="well"
              :wellSelection="wellSelection"
              :key="index"
              :barIndex="index"
              :displayId="displayId"
              :widgetId="widgetId"
              :widgetIndex="widgetIndex"
              :displayIndex="displayIndex"
              :correlationIdx="idx"
            /> -->
            <Trend
              class="plot"
              :id="widgetId + index"
              :key="index"
              :trendIndex="index"
              :height="height"
              :width="
                this.$store.state.disp.rects[this.displayIndex]
                  ?.isCorrelationLeftBar
                  ? (parseInt(width) /
                      this.$store.state.rect.rects[idx]
                        ?.wellsToRelate?.length) *
                    (1 - 0.09)
                  : (parseInt(width) /
                      this.$store.state.rect.rects[idx]
                        ?.wellsToRelate?.length) *
                    (1 + 0.01)
              "
              :top="top"
              :left="left"
              :displayId="displayId"
              :plotConfigProps="plotConfig"
              :displayIndex="displayIndex"
              :widgetIndex="widgetIndex"
              :correlationIdx="idx"
              :requiredData="wellCorrelationCompleteData[well.well_id]"
              :well_id="well.well_id"
              :wellDetails="well"
              :isReplay="isReplay"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Trend from "../Correlation/CorrelationTrend.vue";
// import CorrelationBar from "../../Navbars/CorrelationBar.vue";
import Correlation_leftBar from "../../Sidebar/Correlation_sidebar.vue";
import CoreMap from "./CoreMap.vue";
import DaysVsDepth from "../DaysVsDepth/DaysVsDepth.vue";
import apiService from "../../../api/services";
export default {
  name: "correlation-plot",
  data() {
    return {
      idx:'',
      randomId:'',
      correlation: [],
      open_map: true,
      ismap: false,
      wellCorrelationCompleteData: {},
      mnemonics: [
        "dmea",
        "rpm",
        "spp",
        "wob",
        "diff",
        "mfi",
        "rop",
        "tor",
        "simplifiedmse",
        "tvd",
        "inclination",
        "gamma_ray",
        "dls",
        "rop",
        'sl_res'
      ],
    };
  },
  
  computed:{
    rects(){
      this.idx = this.getIndex(this.$store.state.rect,this.widgetId,this.displayId)
      return this.$store.state.rect.rects
    }
  },
  mounted() {
    this.idx = this.getIndex(this.$store.state.rect,this.widgetId,this.displayId)
    if(this.idx || this.idx == 0){
      if(Object.keys(this.$store.state.rect.rects[this.idx]).includes('wellsToRelate') && 
        this.$store.state.rect.rects[this.idx].wellsToRelate.length
      ){
        this.$store.state.rect.rects[this.idx].wellsToRelate.map(async (obj)=>{
          if (
            !this.wellCorrelationCompleteData[obj.well_id]
          ) {
            let minMax = await this.getMinMaxRange(obj);
            this.getMultiTagsData(
              obj,
              parseInt(minMax.max - 500),
              parseInt(minMax.max)
            );
          }
        })
      }
    }
    this.randomId = Math.random()
    this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "rect/wellsToRelate":
          
          if (this.displayId == mutation.payload.displayId) {
            if (
              !this.wellCorrelationCompleteData[mutation.payload.value.well_id]
            ) {
              let minMax = await this.getMinMaxRange(mutation.payload.value);
              this.getMultiTagsData(
                mutation.payload.value,
                parseInt(minMax.max - 500),
                parseInt(minMax.max)
              );
            }
          }
        // if(mutation.payload.widgetIndex == idx)
        // this.correlation = this.$store.state.rects[idx].wellsToRelate
        //
        break;
        case 'data/setCustomer':
          this.randomId = Math.random()
          break;
      }
    });
  },
  props: {
    widgetId: String,
    height: [String, Number],
    width: [String, Number],
    top: [String, Number],
    left: [String, Number],
    displayId: [String, Number],
    key: [String, Number],
    widgetIndex: [String, Number],
    displayIndex: [String, Number],
    plotConfig: {
      type: [Object, Array],
    },
    parentW: [Number, String],
    parentH: [Number, String],
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },

  },
  components: {
    Trend,
    // CorrelationBar,
    CoreMap,
    Correlation_leftBar,
    DaysVsDepth,
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    getIndex(state, widgetId, displayId) {
      
      let index = -1
      for (let i = 0; i < state.rects.length; i++) {
        if (
          state.rects[i].widgetId == widgetId &&
          state.rects[i].displayId == displayId
        ) {
          index = i;
          return index;
        }
      }
},
    async getMultiTagsData(welldetails, scaleMin, scaleMax) {
      try {
        if (!this.wellCorrelationCompleteData[welldetails.well_id])
          this.wellCorrelationCompleteData[welldetails.well_id] = {};
        this.$store.dispatch("data/startLoading");
        
        this.mnemonics = [...new Set( this.mnemonics)]
        let result = await apiService.WellServices.getDepthLogs({
          fields: this.mnemonics,
          well_id: welldetails.well_id,
          wellbore_name:welldetails.wellbore_name,
          // isPartial: true,
          // start: scaleMin,
          // end: scaleMax,
        });
        // apiService.WellServices.getDepthLogs({
        //   fields: this.mnemonics,
        //   well_id: welldetails.well_id,
        //   wellbore_name:welldetails.wellbore_name,
        // })
        //   .then(async (data) => {
        //     if (data && data.data && data.data.data) {
        //       this.wellCorrelationCompleteData[welldetails.well_id] = {
        //         ...this.wellCorrelationCompleteData[welldetails.well_id],
        //         ...data.data.data,
        //       };
        //     }
        //   })
        //   .catch((e) => {
        //     console.error("whole data error : ", e);
        //   });

        this.wellCorrelationCompleteData[welldetails.well_id] = {
          ...this.wellCorrelationCompleteData[welldetails.well_id],
          ...result.data.data,
        };
        this.$store.dispatch("data/stopLoading");
      } catch (e) {
        console.error("error in multidata : ", e);
        this.$store.dispatch("data/stopLoading");
      }
    },
    async getMinMaxRange(welldetails) {
      try {
        let result = null;
        result = await apiService.WellServices.getMinMaxDepth({
          well_id: welldetails.well_id,
          wellbore_name:welldetails.wellbore_name,
        });
        result.data.min = result.data.min;
        result.data.max = result.data.max;
        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    wellSelection(data, index) {},
    onDrop(event, data) {},
    printDiv(divName) {
      var mywindow = window.open("", "PRINT");
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      mywindow.document.write(
        "<html><head>" + document.head.innerHTML + "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(printContents);
      mywindow.document.write("</body></html>");

      setTimeout(() => {
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
      }, 500);

      return true;
    },
  },
};
</script>
<style scopped src="../../../assets/styles/leftbar_styles.css"></style>
